.shop-section-wrapper{
    width:100%;
    height:fit-content;
    border-radius:10px;
    padding:60px 10px 10px 10px;
    display:flex;
    flex-direction:column;
    color:white;
    font-size:1.4em;
    gap:5px;
    
}
.shop-section-cards-wrapper{
    width:90%;
    margin:auto;
    height:max-content;
    padding:5px 5px 40px 5px;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap:10px;
}
.hamper-content{
    width:100%;
    height:100%;
    word-wrap: break-word;
}
@media all and (max-width:1300px)
{
    .shop-section-cards-wrapper{
        width:100%;
    }
}
@media all and (max-width:1200px)
{
    .shop-section-cards-wrapper{
        width:80%;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media all and (max-width:1000px)
{
    .shop-section-cards-wrapper{
        width:100%;
    }
}
@media all and (max-width:800px)
{
    .shop-section-cards-wrapper{
        width:80%;
        grid-template-columns: repeat(1, 1fr);
    }
}
@media all and (max-width:500px)
{
    .shop-section-cards-wrapper{
        width:100%;
    }
}