.header{
    width:100%;
    height:80px;
    background-color:RGBA(34, 34, 34);
    padding:0px 10px 0px 10px;
    display:grid;
    grid-template-columns: repeat(9,1fr);
    place-items:center;
    position: fixed;
    z-index:99999;
}
.header-logo-wrapper{
    width:100%;
    height:80px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    color:#969696;
    grid-column: 1/3;
}
.header-logo-wrapper>h1{
    color:white;
    line-height:45px;
}
.logo{
    width:auto;
    height:70px;
}
.letter-color{
    color:#f4cf7d;
    letter-spacing:10px;

}
.nav-bar{
    width:100%;
    display:flex;
    align-items: center;
    padding:0px 10px 0px 10px;
    grid-column:4/7;
}
.header>ul{
    grid-column:9/-1;
    width:100%;
    display:grid;
    place-items:center;
    list-style:none;
}

.header nav ul{
    width:100%;
    display:flex;
    list-style:none;
    justify-content:space-around;
}
.header-links{
    text-decoration:none;
    color:#f4cf7d;
    letter-spacing:4px;
    font-weight:100;
}
.hamburger-menu-icon{
    display:none;
}
.hamburger-nav-bar{
    display:none;
}
@media all and (max-width:1200px){
    .header-logo-wrapper{
        grid-column:1/2;
    }
    .header-logo-wrapper>h1{
        font-size:1.5em;
    }
    .logo{
        transform:scale(0.9);
    }
    .nav-bar{
        grid-column:3/8;
    } 
}
@media all and (max-width:1000px){
    .nav-bar{
        display:none;
    }
    .header>ul{
        display:none;
    }
    .hamburger-menu-icon{
        display:block;
        grid-column:9/-1;
        font-size:3em;
        color:#f4cf7d;
    }
    .hamburger-nav-bar{
        position:absolute;
        top:0;
        right:-100%;
        background-color:rgba(34,34,34,0.9);
        background-color: rgba(0,0,0,0.9);
        background-image:url(../images/bg.gif);
        background-size: 300px auto;
        background-blend-mode: color;
        /* background:rgba(150,150,150,0.8); */
        width:100%;
        display:block;
        height:calc(100vh);
        padding:30px;
        transition:all 200ms ease 0s;    
    }
    .hamburger-nav-bar-active{
        right:0%;
    }
    .hamburger-nav-bar>ul{
        padding:30px;
        display:flex;
        flex-direction:column;
        width:100%;
        height:90%;
        align-items:flex-end;
    }
    .hamburger-nav-bar>ul>li{
        border-radius:10px;
    }
    .hamburger-links{
        font-size: 2rem;
        color:#f4cf7d;
        width:100%;
        text-decoration:none;
        letter-spacing:10px;
    }
    .close-menu-icon{
        position:absolute;
        top:2%;
        right:3%;
        font-size: 3em;
        background:#222222;
        color:#f4cf7d;
        cursor:pointer;
    }
    .creator-info-wrapper{
        position:absolute;
        width:100%;
        bottom:3%;
        left:0;
        color:white;
        font-family: "Raleway" ,sans-serif;
    }
    .creator-info-link{
        font-family: 'Raleway Dots', cursive;
        font-weight: 20;
        text-decoration: none;
        color:#4cc95b;
    }
}
@media all and (max-width:500px){
    .header{
        display:flex;
        justify-content:space-between;
        padding:0px 5px;
    }
    .header-logo-wrapper{
        align-items:center;
        justify-content:space-between;
        width:10%;
        grid-column:1/2;
    }
    .header-logo-wrapper>h1{
        font-size:1.1em;
        line-height:30px;
    }
    .logo{
        margin:0;
        padding:0;
        transform:scale(0.5);
    }
    .hamburger-menu-icon{
        font-size:2em;
    }
    .close-menu-icon{
        font-size:2em;
    }
    .hamburger-links{
        font-size:1.5em;
    }

}