.faq-wrapper {
  display: flex;
  gap: 30px;
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.questions-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 30px;
}
.faq {
  z-index: 1;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  height: max-content;
  border: 1px solid #494949;
  padding: 20px;
  border-radius: 10px;
  transform-origin: top;
  transition: all 500ms ease 0s;
}
.faq > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: space-around;
  border-radius: 10px;
  transform-origin: top;
  gap: 30px;
}
.faq-questions {
  color: rgba(255, 255, 255, 0.9);
  color: #f4cf7d;
  font-size: 1.2em;
  letter-spacing: 2px;
  word-spacing: 3px;
  z-index: 1;
}
.faq-answers {
  /* transform:scale(1,0); */
  display: none;
  transform-origin: top;
  width: fit-content;
  transition: all 200ms ease 0s;
  z-index: -1;
}
.faq-answers-active {
  display: block;
  color: #969696;
  font-size: 1.3em;
  line-height: 30px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.9);
  word-spacing: 3px;
  /* transform:scale(1); */
  transform-origin: top;

  transition: all 200ms ease 0s;
}

.faq:hover {
  transform: scale(1.02);
}
.answers-icon {
  color: #f4cf7d;
  transform: rotate(0deg) scale(1.5);
  padding: 2px;
  margin-right: 10px;
  transition: 400ms ease 0s;
}
.faq:hover .answers-icon {
  transform: rotate(360deg) scale(1.5);
}
.faq-dropdown-icon {
  font-size: 2em;
  color: #f4cf7d;
  align-self: flex-start;
  transition: all 200ms ease 0s;
}
.faq-dropdown-icon-active {
  transform: rotateX(180deg);
  transition: all 200ms ease 0s;
}
@media all and (max-width: 1200px) {
  .faq-wrapper {
    flex-direction: column;
    gap: 0px;

    align-items: unset;
    justify-content: center;
  }
  .questions-wrapper {
    height: fit-content;
  }
}
@media all and (max-width: 600px) {
  .faq-answers-active {
    font-size: 1.2em;
    line-height: 25px;
  }
}
@media all and (max-width: 500px) {
  .faq-wrapper {
    align-items: unset;
    padding: 10px;
    gap:30px;
  }
  .faq-questions {
    line-height: 25px;
  }
  .questions-wrapper {
    width: 100%;
    height: max-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }
}
