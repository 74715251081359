*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}
.services-wrapper{
    width:100%;
    height:100vh;
    background-color:RGBA(34, 34, 34);
    color:white;
    overflow-y: auto;
    padding-top:100px;
}
.headline-wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align: center;
    height:40%;
}
.headline-wrapper>h1{
    letter-spacing: 20px;
    font-weight:100;
    color:white;
}
.headline-wrapper>p{
    word-spacing:3px;
    letter-spacing:2px;
    padding:0px 10px 0px 10px;
}
.services-inner-wrapper{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    place-items:center;
    height:max-content;
    width:100%;
    gap:20px;
    padding:40px 20px 40px 20px;
}
.services{
    width:500px;
    height:500px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;
    text-align: center;
    padding:20px 5px 0px 5px;
    border-radius:20px;
    box-shadow:0px 0px 3px 0.01px #f4cf7d;
    line-height:30px;
    background: url(../images/bg.gif);
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: color;
    background-size: 300px 300px;
}
.services>h1{
    letter-spacing:4px;
    font-weight:100;
    color:white;
}
.services>p{
    height:200px;
    font-size:1.2em;
    word-spacing:5px;
    letter-spacing:2px;
    padding:20px;
    overflow-y: scroll;
}
.services>p>a{
    text-decoration:none;
    color:#f4cf7d;
}
.icon{
    font-size:30px;
    color:#f4cf7d;
    

}
.return>p>span{
    color:#f4cf7d;
    font-size:1.3em;
    letter-spacing:0;
    margin:0;
    padding:0;
    font-weight:100;
}
@media all and (max-width:1000px)
{
    .services{
        width:100%;
        font-size: 0.9em;
    }
}
@media all and (max-width:800px)
{
    .services-inner-wrapper{
        grid-template-columns: repeat(1,1fr);
    }
    .services{
        width:500px;
    }
}
@media all and (max-width:600px)
{
    .services{
        width:90%;
    }
}
@media all and (max-width:500px)
{
    .services{
        height:380px;
    }
}