.photo-gallery-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:90px;
}
@media all and (max-width: 1200px) {
  .photo-gallery-container {
    align-items: unset;
    flex-direction: column;
    padding: 0px;
  }
}
