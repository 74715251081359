*{
    margin:0;
    padding:0;
}
.contact-outer-wrapper{
    display:flex;
    flex-direction:column;
    overflow-y:auto;
    gap:100px;
    padding-bottom:30px;
    padding-top:100px;
    background-color:#222222;
    height:100vh;
}
.map-wrapper{
    width:100%;
    height: 600px;
}
.contact-wrapper{
    width:100%;
    height:100vh;    
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    background-color:RGBA(34, 34, 34);
    color:#969696;
    padding:100px 0px 0px 0px;

}
.contact-one{
    display:flex;
    flex-direction:column;
    gap:30px;
    justify-content:space-evenly;
    line-height:30px;
}
.contact-one>h1{
    color:white;
    font-weight:100;
    word-spacing:10px;
    letter-spacing:10px;


}
.contact-one>p{
    font-size:1.2em;
    letter-spacing:2px;
    word-spacing:3px;
}

.social-links-container>ul{
    display:flex;
    width:max-content;
    gap:30px;
    align-items:center;
}
.social-links-container>ul>li{
    list-style-type:none;

}
.social-links-container>ul>li>a{
    color:white;
    width:100%;
    height:100%;
    font-size:1.6rem;
    

}
.social-icons{
    transition:all 500ms ease 0s;
    color:whitesmoke;
    display:grid;
    place-items:center;
}
.social-icons:hover{
    transform:rotate(360deg) scale(1.1);
}
.whatsapp:hover{
    color:#4cc95b;

}
.facebook:hover{
    color:#4167b2;
}
.instagram:hover{
    color:#7b3bc3;
}
.youtube:hover{
    color:#fe0000;
}
.linkedin:hover{
    color:#0b65c3
}
.mail:hover{
    color:#f44236;
}


.contact-two{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
}
.contact-two>h3{
    font-weight:100;
    color:white;
    word-spacing:5px;
    letter-spacing:5px;
}
.contact-two>table{
    font-weight:300;
    padding:20px;
    width:100%;
    height:100%;
    font-weight:100;
    display:flex;
    flex-direction:column;
    gap:3px;
}
.contact-two tr{
    padding:5px;
    display:flex;
    justify-content:space-between;
    border:0.01px solid #494949;
    border-radius:10px;
}
td{
    padding:3px;
    text-align: left;
    display:flex;
    justify-content:left; 
    
}
span{
    width:100%;
    text-align: center;
    letter-spacing: 10px;
}
@media all and (max-width:1000px)
{
    .contact-one{
        width:500px;
    }
}
@media all and (max-width:900px)
{
    .contact-wrapper{
        flex-direction: column;
        text-align: center;
        gap:30px;
    }
    .social-links-container{
        width:fit-content;
        margin: auto;
    }
}
@media all and (max-width:600px)
{
    .contact-one{
        width:100%;
        padding:20px;
        height:max-content;
    }
    .whatsapp{
        color:#4cc95b;
    
    }
    .facebook{
        color:#4167b2;
    }
    .instagram{
        color:#7b3bc3;
    }
    .youtube{
        color:#fe0000;
    }
    .linkedin{
        color:#0b65c3
    }
    .mail{
        color:#f44236;
    }
}
