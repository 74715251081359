*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Amita', cursive;
}
.home-outer-wrapper{
    width:100%;
    height:100vh;
    background-color:#222222;
    display:flex;
    flex-direction: column;
    gap:100px;
    overflow-y:auto;
    overflow-x:hidden;
    padding-bottom:20px;

}
.fixed-chat-icon{
    position:fixed;
    bottom:2%;
    right:2%;
    z-index:9999;
    font-size:1.8em;
    width:60px;
    height:60px;
    border-radius:50%;
    background-color:#222222;
    display:grid;
    place-items:center;
    box-shadow:0px 0px 4px 2px black;
    color:#4cc95b;
    transition:all 400ms ease 0s;
}
.fixed-chat-icon:hover{
    transform:rotate(360deg);
}
.scroll-select{
    transform:translateY(100px);
    opacity:0;
    transition:opacity 1s ease-in-out , transform 2s ease 0s; 
}
.scroll-active{
    transform:translateY(0px);
    opacity:1;

}

























