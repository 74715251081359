.shop-card-wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px;
  display: flex;
  border-radius: 10px;
  transition: all 200ms ease 0s;
  overflow: hidden;
  background: url(../../images/bg.gif);
  background-size: 200px 200px;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: color;

  border: 1px solid #f4cf7d;
}
.shop-card-wrapper:hover {
  transform: scale(1.1);
  z-index: 9999;
  background-color: black;
  cursor: pointer;
}
.shop-card-wrapper:hover .buy-button {
  box-shadow: 0px 0px 1px 1px #f4cf7d;
}
.card-description-wrapper {
  position: absolute;
  display: none;
  background-color: black;
  transform-origin: right;
  transition: all 400ms ease 0s;
  overflow: hidden;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 30px;
}
.card-description-wrapper > p {
  width: 90%;
  word-wrap: break-word;
  line-height: 40px;
  word-spacing: 10px;
}
.card-description-wrapper-active {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  transition: all 400ms ease 0s;
  overflow-y: scroll;
}
.card-image {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
.card-content-wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.card-content-wrapper > h4 {
  font-size: 1.1em;
}
.rupees-sign {
  color: #f4cf7d;
}
.buy-button {
  width: max-content;
  text-decoration: none;
  box-shadow: 0px 0px 1px 1px #969696;
  padding: 7px;
  color: white;
  border-radius: 10px;
  font-size: 0.9em;
  font-family: "poppins", sans-serif;
  transition: all 250ms ease-in-out;
}
.buy-button:hover {
  transform: scale(1.05);
  z-index: 9999;
}
@media all and (max-width: 400px) {
  .shop-card-wrapper {
    height: 200px;
    font-size: 0.9em;
  }
  .card-content-wrapper > h4 {
    font-size: 0.9em;
  }
}
