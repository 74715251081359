.shop-outer-wrapper{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    padding:80px 10px 10px 10px;
    background-color:#222222;
    overflow-y: auto;

}
.headline-wrapper{
    font-size:1.5rem;
}