.about-wrapper{
    display:flex;
    flex-direction:column;
    padding:20px;
}
.about-contents-wrapper{
    display:grid;
    grid-template-columns:repeat(2,1fr);
    column-gap:30px;
}
.about-content{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    gap:30px;
}
.content-one>h1{
    color:#969696;
    letter-spacing: 5px;
}
.content-one>h1>span{
    color:white;
}
.founder-image{
    width:300px;
    height:400px;
    object-fit: cover;
    object-position: top;
    border-radius:20px;
     border:2px solid #f4cf7d;
     padding:20px;
}
.content-one>p{
    width:50%;
    font-size:1.2em;
    line-height:1.7em;
    color:#969696;
    word-spacing:0.4em;
}
.content-two>h1{
    width:50%;
    color:#969696;
    justify-self:left;
    letter-spacing:2px;
    word-spacing:3px;
}
.content-two>table{
    width:50%;
    font-size:1.1em;
    line-height:1.7em;
    color:#969696;
    word-spacing:0.4em;
    justify-self: flex-start;
}
.content-two>table>tr{
    display:flex;
    flex-direction:column;
    gap:8px;
    align-items:left;
    justify-content:left;
    text-align:left;
}
.content-two>table>tr>th{
    letter-spacing:0.2em;
    word-spacing:0.2em;
    border:0.01px solid #494949;
    padding:6px;
    border-radius:10px;
}
.content-two>table>tr>th>span{
    color:#f4cf7d;
    font-size:1.3em;
    letter-spacing:0;
    margin:0;
    padding:0;
    font-weight:100;
}
@media all and (max-width:1000px){
    .content-one>p{
        width:80%;
    }
    .content-two>h1{
        width:80%;
    }
    .content-two>table{
        width:80%;
    }
}
@media all and (max-width:800px){
    .content-one>p{
        width:100%;
    }
    .content-two>h1{
        width:100%;
    }
    .content-two>table{
        width:100%;
    }
}
@media all and (max-width:700px){
    .about-wrapper{
    padding:5px;
    }
    .content-one>h1{
        width:100%;
        color:#969696;
        letter-spacing: 5px;
    }
    .content-one>p{
        width:100%;
        line-height:1.7em;
    }
    .about-contents-wrapper{
        display:grid;
        grid-template-columns:repeat(1,1fr);
        row-gap:30px;
        place-items:center;
    }
    .content-two>h1{
        width:100%;
    }
    .content-two>table{
        width:90%;
    }
    .content-two>table>tr>th{
        padding:5px;
    }
    
}